exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integritetspolicy-jsx": () => import("./../../../src/pages/integritetspolicy.jsx" /* webpackChunkName: "component---src-pages-integritetspolicy-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-nyheter-jsx": () => import("./../../../src/pages/nyheter.jsx" /* webpackChunkName: "component---src-pages-nyheter-jsx" */),
  "component---src-pages-om-oss-jsx": () => import("./../../../src/pages/om-oss.jsx" /* webpackChunkName: "component---src-pages-om-oss-jsx" */),
  "component---src-pages-oppettider-jsx": () => import("./../../../src/pages/oppettider.jsx" /* webpackChunkName: "component---src-pages-oppettider-jsx" */),
  "component---src-pages-padelregler-jsx": () => import("./../../../src/pages/padelregler.jsx" /* webpackChunkName: "component---src-pages-padelregler-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-priser-jsx": () => import("./../../../src/pages/priser.jsx" /* webpackChunkName: "component---src-pages-priser-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-sok-jobb-jsx": () => import("./../../../src/pages/sok-jobb.jsx" /* webpackChunkName: "component---src-pages-sok-jobb-jsx" */),
  "component---src-pages-var-hall-jsx": () => import("./../../../src/pages/var-hall.jsx" /* webpackChunkName: "component---src-pages-var-hall-jsx" */)
}

