import React from "react";
import { AnimatePresence } from "framer-motion";
import { ApolloProvider } from "@apollo/client";
import { client } from "./src/apollo/client";
import "./src/styles/global.css";
import "./src/styles/_index.scss";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
);

export const onClientEntry = () => {
  if (navigator && navigator?.userAgent?.match(/samsung/i)) {
    alert(
      "Samsung Internet (med darkmode aktiverat) är " +
        "inte är konfigurerad för att visa denna webbplats korrekt. " +
        "Du bör överväga att använda en korrekt standardkompatibel webbläsare  " +
        "istället. \n\n" +
        "Vi rekommenderar att du använder Google Chrome eller Firefox."
    );
  }
};
